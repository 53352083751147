<template>
  <b-row>
    <b-col cols="12">
      <b-overlay
        :show="$apollo.loading || mutationLoading"
        spinner-variant="primary"
      >
        <div>
          <form-wizard
            :subtitle="null"
            :title="null"
            back-button-text="Previous"
            class="mb-3"
            color="#7367F0"
            finish-button-text="Submit"
            shape="square"
            @on-complete="addOrganization"
          >

            <!-- Basic Info tab -->
            <tab-content
              :before-change="validationForm"
              title="Basic Information"
            >
              <validation-observer
                ref="startupBasicInfoRules"
                tag="form"
              >
                <b-row>
                  <b-col
                    class="mb-2"
                    cols="12"
                  >
                    <h5 class="mb-0">
                      Basic Information
                    </h5>
                  </b-col>

                  <b-col md="6">
                    <b-form-group
                      label="Organization Name"
                      label-for="organization"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Organization Name"
                        rules="required"
                      >
                        <b-form-input
                          id="organization"
                          v-model="organization.title"
                          :state="errors.length > 0 ? false:null"
                          placeholder="Organization Name"
                          type="text"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group
                      label="Designation"
                      label-for="designation"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Designation"
                        rules="required"
                      >
                        <b-form-input
                          id="designation"
                          v-model="organization.designation"
                          :state="errors.length > 0 ? false:null"
                          placeholder="Your Designation"
                          type="text"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="12">
                    <b-form-group
                      label="Description"
                      label-for="description"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Description"
                        rules="required"
                      >
                        <b-form-textarea
                          id="description"
                          v-model="organization.description"
                          :state="errors.length > 0 ? false:null"
                          placeholder="Organization Description"
                          rows="2"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="7">
                    <b-form-group
                      label="Registered Name"
                      label-for="registered_title"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Registered Name"
                        rules="required"
                      >
                        <b-form-input
                          id="registered_title"
                          v-model="organization.registered_title"
                          :state="errors.length > 0 ? false:null"
                          placeholder="Registered Name"
                          type="text"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <b-col md="5">
                    <b-form-group
                      label="Type(s)"
                      label-for="type"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Type"
                        rules="required"
                      >
                        <v-select
                          id="type"
                          v-model="organization.type"
                          :options="['Company', 'Individual']"
                          :state="errors.length > 0 ? false:null"
                          placeholder="Select Type(s)"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="4">
                    <b-form-group
                      label="Official Email"
                      label-for="official_email"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Official Email"
                        rules="required"
                      >
                        <b-form-input
                          id="official_email"
                          v-model="organization.official_email"
                          :state="errors.length > 0 ? false:null"
                          placeholder="hello@yourbusiness.com"
                          type="text"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="4">
                    <b-form-group
                      label="Phone Number"
                      label-for="url"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Phone Number"
                        rules="required"
                      >
                        <b-form-input
                          id="official_phone"
                          v-model="organization.official_phone"
                          :state="errors.length > 0 ? false:null"
                          placeholder="+91-1234567890"
                          type="text"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="4">
                    <b-form-group
                      label="Website"
                      label-for="url"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Website"
                        rules="required"
                      >
                        <b-form-input
                          id="url"
                          v-model="organization.url"
                          :state="errors.length > 0 ? false:null"
                          placeholder="Organization Website URL"
                          type="text"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="12">
                    <b-form-group
                      label="Industry"
                      label-for="industry"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Industry"
                        rules="required"
                      >
                        <v-select
                          id="industry"
                          v-model="organization.industry"
                          :options="industries"
                          :state="errors.length > 0 ? false:null"
                          multiple
                          placeholder="Select Industry"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group
                      label="City"
                      label-for="city"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="City"
                        rules="required"
                      >
                        <b-form-input
                          id="city"
                          v-model="organization.city"
                          :state="errors.length > 0 ? false:null"
                          placeholder="City"
                          type="text"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group
                      label="State"
                      label-for="state"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="State"
                        rules="required"
                      >
                        <b-form-input
                          id="state"
                          v-model="organization.state"
                          :state="errors.length > 0 ? false:null"
                          placeholder="Organization Website URL"
                          type="text"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
              </validation-observer>
            </tab-content>
          </form-wizard>
        </div>
        <b-modal
          id="makeDefaultModal"
          ok-title="Yes"
          button-size="sm"
          no-close-on-backdrop
          centered="true"
          size="sm"
          title="Make Default"
          @ok="makedefault()"
        >
          <h6>Do you want to make this organization as default?</h6>
        </b-modal>
      </b-overlay>
    </b-col>
  </b-row>
</template>

<script>
import {
  BCol, BFormGroup, BFormInput, BFormTextarea, BRow,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { FormWizard, TabContent } from 'vue-form-wizard'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { email, required } from '@core/utils/validations/validations'
import gql from 'graphql-tag'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import vSelect from 'vue-select'
import { getUserData } from '@/auth/utils'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    FormWizard,
    TabContent,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    vSelect,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  data() {
    return {
      mutationLoading: false,
      newadded: 0,
      organization: {
        title: null,
        type: null,
        designation: null,
        description: null,
        registered_title: null,
        official_email: null,
        official_phone: null,
        url: null,
        industry: null,
        city: null,
        state: null,
      },
      industries: ['Agriculture and Allied Industries',
        'Automobiles',
        'Auto Components',
        'Aviation',
        'Banking',
        'Biotechnology',
        'Cement',
        'Chemicals',
        'Consumer Durables',
        'Defence Manufacturing',
        'E-Commerce',
        'Education and Training',
        'Electronics System Design and Manufacturing',
        'Engineering and Capital Goods',
        'Financial Services',
        'FMCG',
        'Gems and Jewellery',
        'Healthcare',
        'Infrastructure',
        'Insurance',
        'IT and BPM',
        'Manufacturing',
        'Media and Entertainment',
        'Medical Devices',
        'Metals and Mining',
        'MSME',
        'Oil and Gas',
        'Pharmaceuticals',
        'Ports',
        'Power',
        'Railways',
        'Real Estate',
        'Renewable Energy',
        'Retail',
        'Roads',
        'Science and Technology',
        'Services',
        'Steel',
        'Telecommunications',
        'Textiles',
        'Tourism and Hospitality'],
      required,
      email,
    }
  },
  methods: {
    validationForm() {
      return new Promise((resolve, reject) => {
        this.$refs.startupBasicInfoRules.validate()
          .then(success => {
            if (success) {
              resolve(true)
            } else {
              reject()
            }
          })
      })
    },
    mutationObject() {
      const startupDetails = { ...this.organization }
      // eslint-disable-next-line no-underscore-dangle
      delete startupDetails.assoc_id
      delete startupDetails.designation
      startupDetails.industry = startupDetails.industry.join(',')
      const object = {
        users_organizationtable: {
          data: {
            ...startupDetails,
          },
          on_conflict: {
            constraint: 'users_organizationtable_pkey',
            update_columns: ['title',
              'type',
              'description',
              'registered_title',
              'official_email',
              'official_phone',
              'url',
              'industry',
              'city',
              'state'],
          },
        },
        designation: this.organization.designation,
        role: 'partner',
        user_id: getUserData().id,
      }
      if (this.organization.assoc_id) object.id = this.organization.assoc_id
      delete object.assoc_id
      return object
    },
    addOrganization() {
      this.mutationLoading = true
      let returntrue = 0
      this.$apollo.mutate({
        // Query
        mutation: gql`mutation update_org_details($object: users_associationtable_insert_input! ) {
          insert_users_associationtable_one(on_conflict: {update_columns: designation, constraint: users_associationtable_pkey}, object: $object) {
            id
            organization_id
          }
        }`,
        variables: {
          object: this.mutationObject(),
        },
        update: (store, { data: { insert_users_associationtable_one } }) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: insert_users_associationtable_one.id ? 'Organization Updated' : 'There was an Error',
              icon: 'EditIcon',
              variant: insert_users_associationtable_one.id ? 'success' : 'danger',
            },
          })
          this.mutationLoading = false
          this.newadded = insert_users_associationtable_one.organization_id
          returntrue = 1
          this.$bvModal.show('makeDefaultModal')
          this.$apollo.queries.organization.refetch()
        },
      })
      // this.resetValues()
      if (returntrue) return true
      return this.error
    },
    makedefault() {
      this.mutationLoading = true
      this.$apollo.mutate({
        // Query
        mutation: gql`mutation {
          update_users_customuser_by_pk(pk_columns: {id: ${getUserData().id} }, _set: {default_startup: ${this.newadded}}) {
            id
          }
        }`,
        update: (store, { data: { update_users_customuser_by_pk } }) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: update_users_customuser_by_pk.id ? 'Organization Made Default' : 'Error Making Default',
              icon: 'EditIcon',
              variant: update_users_customuser_by_pk.id ? 'success' : 'danger',
            },
          })
          this.mutationLoading = false
          this.$apollo.queries.organization.refetch()
          window.location.reload()
        },
      })
    },
  },
  apollo: {
    organization: {
      query() {
        return gql`{
          users_associationtable_by_pk(id: ${this.$route.params.id}) @include(if: ${!!this.$route.params.id}) {
            id
            designation
            users_organizationtable {
              id
              title
              type
              description
              registered_title
              official_email
              official_phone
              url
              industry
              city
              state
            }
          }
        }`
      },
      update(data) {
        return (data.users_associationtable_by_pk ? {
          ...data.users_associationtable_by_pk.users_organizationtable,
          assoc_id: data.users_associationtable_by_pk.id,
          designation: data.users_associationtable_by_pk.designation,
          type: data.users_associationtable_by_pk.users_organizationtable.type.split(','),
        } : {})
      },
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>
